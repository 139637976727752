import Address from "./components/address.vue";
import Cargo from "./components/cargo.vue";
import Company from "./components/company.vue";
import Login from "./components/login.vue";
import Mpopup from "components/Mpopup/Mpopup";
import Modal from "components/Modal/Modal";
import { mapGetters } from "vuex";
export default {
  name: "sntInvite",
  components: {
    Address,
    Mpopup,
    Cargo,
    Company,
    Modal,
    Login,
  },
  filters: {
    filterxzq: (val) => {
      if (!val) return "";
      return val.replace(/,/g, "");
    },
    hidePhone: (val) => {
      if (!val) return "";
      return val.replace(/(\d{3}).{4}(\d{4})/, "$1****$2");
    },
  },
  data() {
    return {
      pageStatus: 0, // 链接状态 427停用 428失效
      companyModel: false,
      cargoModel: false,
      addressModel: false,
      rcgTimer: null,
      addrDetail: {
        xzqName: "",
        name: "",
        phone: "",
        address: "",
      },
      sendman: {
        xzqName: "",
        name: "",
        phone: "",
        address: "",
      },
      recman: {
        xzqName: "",
        name: "",
        phone: "",
        address: "",
      },
      sendId: "",
      sendFirstId:'',
      cargoData: {
        cargo: "",
        cargoComment: "",
        cargoInput: "",
      },
      cargoList: [
        { name: "日用品", data: ["含液体", "含电池"] },
        { name: "服饰" },
        { name: "文件", data: ["含法律公文"] },
        { name: "食品", data: ["含液体"] },
        { name: "证件" },
        { name: "数码家电", data: ["含电池"] },
        { name: "生鲜" },
        { name: "其他", data: ["含液体", "含电池"] },
      ],
      sendData: "",
      recData: "",
      recId: "",
      cargoName: "",
      comList: [],
      comData: {},
      islogin: false,
      sign: "",
      type: "",
      isShowLogin: false,
      position: {
        lat: "",
        lng: "",
      },
      loginType: "",
      haha: "",
      step: 1,
      fixSend: false,
      fixRec: false,
      addrType: "send",
      recFirstId:''
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    province() {
      if (this.sendman.xzqName && this.sendman.xzqName.split(",")) {
        return this.sendman.xzqName.split(",")[0];
      } else {
        return "";
      }
    },
    city() {
      if (this.sendman.xzqName && this.sendman.xzqName.split(",").length > 1) {
        return this.sendman.xzqName.split(",")[1];
      } else {
        return "";
      }
    },
    district() {
      if (this.sendman.xzqName && this.sendman.xzqName.split(",").length > 2) {
        return this.sendman.xzqName.split(",")[2];
      } else {
        return "";
      }
    },
    provinceRec() {
      if (this.recman.xzqName && this.recman.xzqName.split(",")) {
        return this.recman.xzqName.split(",")[0];
      } else {
        return "";
      }
    },
    cityRec() {
      if (this.recman.xzqName && this.recman.xzqName.split(",").length > 1) {
        return this.recman.xzqName.split(",")[1];
      } else {
        return "";
      }
    },
    districtRec() {
      if (this.recman.xzqName && this.recman.xzqName.split(",").length > 2) {
        return this.recman.xzqName.split(",")[2];
      } else {
        return "";
      }
    },
    pageStatusText() {
      const PAGESTATUSTEXTS = {
        427: "该下单页面已停用请联系管理员",
        428: "下单页面已失效",
      };
      return PAGESTATUSTEXTS[this.pageStatus];
    },
  },
  methods: {
    setRecManData(data) {
      const addrInfo = data.addrs[0] || {};
      this.recman.name = data.name;
      this.recman.phone = data.mobile;
      this.recman.province = addrInfo.province || "";
      this.recman.city = addrInfo.city || "";
      this.recman.district = addrInfo.district || "";
      this.recman.address = addrInfo.addr || "";
      this.recman.xzqName = `${addrInfo.province},${addrInfo.city},${addrInfo.district}`;
      this.recman.fullAddress = data.address || "";
    },
    setSendManData(data) {
      // this.ruleForm.SJ_INVITE_DEF_RECEIVER = result.data[0].id
      const addrInfo = data.addrs[0] || {};
      this.sendData = data.name;
      this.sendman.name = data.name;
      this.sendman.phone = data.mobile;
      this.sendman.province = addrInfo.province || "";
      this.sendman.city = addrInfo.city || "";
      this.sendman.district = addrInfo.district || "";
      this.sendman.address = addrInfo.addr || "";
      this.sendman.xzqName = `${addrInfo.province},${addrInfo.city},${addrInfo.district}`;
      this.sendman.fullAddress = data.address || "";
      // this.sendman.sendAddr = result.data[0].addrs[0].addr ? result.data[0].addrs[0].addr : ''
      // this.sendman.sendNewRegional = result.data[0].addrs[0].province + result.data[0].addrs[0].city + (result.data[0].addrs[0].district ? result.data[0].addrs[0].district : '')
      // this.sendman.sendmobile = result.data[0].mobile
      // this.sendman.sendtel = result.data[0].tel
      this.getLatLng(this.sendman.fullAddress, "send");
    },
    getAddrInfo(id, type = "send") {
      const url =
        "/snt/order//open/exclusivesjvisit.do?type=SNTSJINVITE&method=SNTCONTACT";
      let params = {
        data: {
          sign: this.sign,
          id,
        },
      };
      this.$http.post(url, params).then((result) => {
        if (result.status == "200") {
          if (type === "send") {
            this.setSendManData(result.data[0])
          } else if (type === "rec") {
            this.setRecManData(result.data[0])
          }
        }
      });
    },
    getLatLng(address, type = "send") {
      const url =
        "/snt/order/open/exclusivesjvisit.do?type=SNTSJINVITE&method=SNTLONLAT";
      let params = {
        data: {
          sign: this.sign,
          address,
        },
      };
      return this.$http.post(url, params).then((res) => {
        if (res.status == 200 && res.data) {
          const lat = res.data.lat;
          const lon = res.data.lon;
          if (type === "send") {
            this.sendman.lat = lat;
            this.sendman.lon = lon;
          } else if (type === " rec") {
            this.recman.lat = lat;
            this.recman.lon = lon;
          }
        }
      });
    },

    getKdcom() {
      let loading = this.$loading("正在获取快递公司");
      const url =
        "/snt/order/open/exclusivesjvisit.do?type=SNTSJINVITE&method=SNTCUSKUAIDICOM";
      let params = {
        data: {
          sign: this.sign,
          sendLon: this.sendman.lon ? this.sendman.lon : '',
          sendLat: this.sendman.lat ? this.sendman.lat : '',
          sendXzq: `${this.province}#${this.city}#${this.district}`,
          fromAddress: this.sendman.address,
          recXzq: `${this.provinceRec}#${this.cityRec}#${this.districtRec}`,
          toAddress: this.recman.address,
        },
      };
      this.$http
        .post(url, params)
        .then((res) => {
          if (res.status == 200) {
            this.comList = res.data;
            this.comData = {};
          }
          loading.hide();
        })
        .catch(() => loading.hide());
    },
    showComName({kuaidicom:code,capacityId}) {
      if (code) {
        const KUAIDICOMCNTRANS = {
          88: '圆通-SW',
          89: '圆通-DD',
          90: '圆通-TS'
        };
        if(KUAIDICOMCNTRANS[capacityId]){
          return KUAIDICOMCNTRANS[capacityId]
        }
        return jsoncom.company.filter((item) => {
          // 收件人id
          return item.number === code;
        })[0]?.shortName;
      } else {
        return "";
      }
    },
    locateFail(err) {
      console.log("err", err);
      this.$toast("定位失败");
    },
    getUserInfo() {
      // return new Promise((res, rej) => {
      //   this.$http
      //     .get("/user/userapi.do?method=getuserinfo", {
      //       handleFail: false,
      //     })
      //     .then((data) => {
      //       this.$store.state.userInfo = data.user || {};
      //     })
      //     .finally(() => {
      //       res();
      //     });
      // });
    },
    goList() {
      // 订单列表
      if (!this.islogin) {
        this.loginType = "input";
        return (this.isShowLogin = true);
      }
      this.step = 1;
      location.replace(
        `${location.origin}/mexpress/sntinvite/orderlist?sign=${this.sign}&type=${this.type}`
      );
      /* this.$router.push({
        name: "sntOrderList",
        query: {
          sign: this.sign,
          phone: this.userInfo.mobile
        }
      }) */
    },
    async loginSuccess(tag) {
      // 登录成功
      this.islogin = true;
      await this.getUserInfo();
      if (tag === "submit") {
        this.submit();
      }
    },
    tosuborder() {
      let loading = this.$loading("正在下单");
      const url = "/snt/order/open/exclusiveVisit.do";
      const params = {
        data: {
          type: "SNTNVITEADDEXPRESS", // this.type,
          sign: this.sign,
          com: this.comData.kuaidicom,
          recman: this.recId,
          sendman: this.sendId,
          servicetype: this.comData.serviceType[0],
          count: 1,
          capacity: this.comData.capacity || "",
          capacityId: this.comData.capacityId || "",
          sourceType: "NATIVE",
          expSentType: this.comData.expSentType,
          sentaddr: this.city,
          cargo:
            this.cargoData.cargo === "其他"
              ? this.cargoData.cargoInput
              : this.cargoData.cargo || this.cargoName,
          comment: this.cargoData.cargoComment || "",
          inviteType: this.SJ_INVITE_PAY_TYPE,
        },
      };
      this.$http
        .post(url, params)
        .then(() => {
          this.clear();
          this.step = 2;
          /* this.$router.push({
          name: "sntSuccess",
          query: {
            sign: this.sign,
            phone: this.userInfo.mobile
          }
        }) */
        })
        .finally(() => {
          loading.hide();
        });
    },
    clear() {
      // 清除信息
      if(!this.sendFirstId){
        this.sendman = {};
      }
      this.cargoData = {
        cargo: "",
        cargoComment: "",
      };
      this.comData = {};
    },
    addnew(info, shiptag = "SENDER") {
      // 添加寄件人
      const url = "/snt/order/open/exclusiveVisit.do";
      const params = {
        data: {
          type: "SNTSENTCONTACT",
          act: "addnew",
          sign: this.sign || "",
          platform: "WWW",
          shiptag,

          name: info.name,
          mobile: info.phone,
          province: info.province,
          city: info.city,
          district: info.district,
          addr: info.address,
        },
      };
      return new Promise((resolve, reject) => {
        this.$http.get(url, params).then((res) => {
          // this.sendId = res?.data[0]?.id || ''
          // this.tosuborder()
          resolve(res);
        });
      });
    },
    async submit() {
      // 下单
      if (!this.fixSend && !this.sendman.name) {
        return this.$toast("请填写寄件人信息");
      }
      if (!this.fixRec && !this.recman.name) {
        return this.$toast("请填写收件人信息");
      }
      if (!this.cargoData.cargo && !this.cargoName) {
        return this.$toast("请填写物品信息");
      }
      if (!this.comData.kuaidicom) {
        return this.$toast("请选择快递公司");
      }
      if (!this.islogin) {
        this.loginType = "input";
        return (this.isShowLogin = true);
      }
      // todo
      if (!this.fixSend) {
        const data = {
          ...this.sendman,
          province: this.province,
          city: this.city,
          district: this.district,
        };
        const res = await this.addnew(data);
        this.sendId = res?.data[0]?.id || "";
      }
      if (!this.fixRec) {
        const data = {
          ...this.recman,
          province: this.provinceRec,
          city: this.cityRec,
          district: this.districtRec,
        };
        const res2 = await this.addnew(data, "ADDRESSEE");
        this.recId = res2?.data[0]?.id || "";
      }
      this.tosuborder();
    },
    confirmCom() {
      // 快递公司
      this.companyModel = false;
    },
    getInfo() {
      const url = "/snt/order/open/exclusivesjvisit.do";
      const params = {
        data: {
          sign: this.sign,
          type: this.type,
        },
      };
      this.$http
        .post(url, params)
        .then((res) => {
          if (res.data?.length) {
            this.recData = res.data.filter((item) => {
              // 收件人名称
              return item.key === "SJ_INVITE_PAGE";
            })[0]?.value;
            document.title = this.recData;
            // this.sendFirstId = res.data.filter((item) => {
            //   // 寄件人id
            //   return item.key === "SJ_INVITE_DEF_SENDER_ADDR";
            // })[0].value;
            res.data.forEach(item=>{
              if(item.key == 'SJ_INVITE_DEF_SENDER_ADDR'){
                this.sendFirstId = item.value
              }
            })
            this.sendId = res.data.filter((item) => {
              // 寄件人id
              return item.key === "SJ_INVITE_DEF_SENDER";
            })[0].value;
            if(this.sendFirstId){
              this.getAddrInfo(this.sendFirstId, "send");
            }else if (this.sendId) {
              this.fixSend = true;
              this.getAddrInfo(this.sendId, "send");
            }
            res.data.forEach(item=>{
              if(item.key == 'SJ_INVITE_DEF_RECEIVER_ADDR'){
                this.recFirstId = item.value
              }
            })
            this.recId = res.data.filter((item) => {
              // 收件人id
              return item.key === "SJ_INVITE_DEF_RECEIVER";
            })[0]?.value;
            if (this.recFirstId) {
              this.getAddrInfo(this.recFirstId, "rec");
            }else if (this.recId) {
              this.fixRec = true;
              this.getAddrInfo(this.recId, "rec");
            }
            
            const list = res.data.filter((item) => {
              // 快递公司
              return item.key === "SJ_INVITE_COMPANY_LIST";
            })[0]?.value;
            this.cargoName = res.data.filter((item) => {
              //物品名称
              return item.key === "SJ_INVITE_CARGO";
            })[0]?.value;
            console.log("list", list);
            this.SJ_INVITE_PAY_TYPE = res.data.filter((item) => {
              //物品名称
              return item.key === "SJ_INVITE_PAY_TYPE";
            })[0].value;
            // this.comList = JSON.parse(list)
            // /* this.comList.push({
            //   bizType: ['快递1111', '快递222222'],
            //   expressCode: "shunfeng",
            //   id: 22,
            //   name: "顺丰-官方-线上2",
            //   payWay: 0,
            //   type: "OFFICIAL",
            // }) */
            // if (this.comList?.length === 1 && this.comList[0].bizType?.length === 1) { // 自动选择
            //   this.comData = this.comList[0]
            //   this.$set(this.comData, 'serviceIndex', 0)
            // }
          }
        })
        .catch((err) => {
          this.pageStatus = err.status;
        });
    },
    selectCom() {
      // 打开公司弹窗
      if (!this.fixSend && !this.sendman.name) {
        return this.$toast("请填写寄件人信息");
      }
      if (!this.fixRec && !this.recman.name) {
        return this.$toast("请填写收件人信息");
      }
      this.companyModel = true;
    },
    cancelCargo() {
      // 关闭物品弹窗
      this.cargoData = JSON.parse(JSON.stringify(this.cargoBackup));
    },
    selectCargo() {
      // 打开物品弹窗
      this.cargoBackup = JSON.parse(JSON.stringify(this.cargoData));
      this.cargoModel = true;
    },
    async confirmMan(info) {
      // 地址弹窗确定
      console.log(info);
      if (this.addrType === "send") {
        this.sendman = info;
        const fullAddress =
          this.province + this.city + this.district + info.address;
        await this.getLatLng(fullAddress);
      } else {
        this.recman = info;
      }

      if (this.sendman.name && this.recman.name) {
        this.getKdcom();
      }
      this.addressModel = false;
    },
    openAddress(type) {
      // 打开地址弹窗
      this.addrType = type;
      if (type === "send") {
        if (this.fixSend) return;
        this.sendmanBackup = JSON.parse(JSON.stringify(this.sendman));
        this.addrDetail = this.sendman;
      } else {
        if (this.fixRec) return;
        this.recmanBackup = JSON.parse(JSON.stringify(this.recman));
        this.addrDetail = this.recman;
      }

      this.addressModel = true;
    },
    closeMan() {
      // 取消地址弹窗
      if (this.addrType === "send") {
        this.sendman = JSON.parse(JSON.stringify(this.sendmanBackup));
      } else {
        this.recman = JSON.parse(JSON.stringify(this.recmanBackup));
      }
    },
    showContact(type) {
      // 地址列表
      if (!this.islogin) {
        this.loginType = "input";
        return (this.isShowLogin = true);
      }
      this.broadcast(); // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "dispatch",
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: -1,
        },
      });
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", async (addr, type) => {
        // this.selectContact(addr, type)
        console.log('addr',addr)
        if(!addr.isShowPhone){
          const data = {
            addrId: addr.id,
            location: 1,
            addrVersion: 0
          }
          const res = await this.$http.get('/pcweb/addressbook/querybaseaddressbook', { data })
          addr.phone = res.data.phone
        }
        if(type=='rec'){
          this.recman = {
            ...this.recman,
            ...addr
          }
        }
        if(type=='send'){
          this.sendman = {
            ...this.sendman,
            ...addr
          }
          this.sendman.fullAddress = addr.address ? addr.xzqName+addr.address : "";
          await this.getLatLng(this.sendman.fullAddress, "send");
        }
        if (this.sendman.name && this.recman.name) {
          this.getKdcom();
        }
        this.$broadcastChannel.$off("fillContact");
      });
    },
  },
  created() {
    this.sign = this.$route.query.sign;
    this.type = this.$route.query.type;
    this.login(() => {
      this.islogin = true;
      this.getUserInfo();
    });
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "login" && vm.$store.state.globalData.token) {
        vm.loginCallback();
      }
    });
  },
  watch: {},
};
