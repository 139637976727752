<template>
  <div class="address">
    <Mpopup v-model="addressModel" :maskClickable="false" @close="closeMan">
      <div class="wrap">
        <h1 class="address-title">填写{{ addrtype === 'rec' ? '收' : '寄'}}件人地址</h1>
        <section class="add-box">
          <div class="slide-wrap">
            <div class="copy" v-if="(!sendFirstId && addrtype == 'send') || (!recFirstId && addrtype == 'rec')">
              <p style="border: none;">
                <textarea
                  v-model="detail.reconize"
                  style="background: #F0F2F3;"
                  class="text-l"
                  placeholder="粘贴或输入文本，如：张三 13987631728 广东省深圳市南山区科技南十二路金蝶软件园6栋B1"
                  @focus="focusLoc"
                  @blur="blurLoc">
                </textarea>
              </p>
            </div>
            <p class="add-info">
              <span class="add-info-label">姓 名</span>
              <input v-model="detail.name" type="text" placeholder="请填写姓名" maxlength="20" class="cont">
            </p>
            <p class="add-info">
              <span class="add-info-label">电 话</span>
              <input v-model="detail.phone" type="text" placeholder="请输入手机号码" maxlength="11" class="cont">
            </p>
            <p class="link add-info">
              <img class="link-img" src="https://cdn.kuaidi100.com/images/uniapp/index/icon-address.png" @click.stop="getLocation" v-if="!(((sendFirstId && addrtype == 'send') || (recFirstId && addrtype == 'rec')) && detail.address)"/>
              <span class="add-info-label">地 区</span>
              <span class="input" :class="{placeholder:!detail.xzqName}" style="width:70%" v-if="((sendFirstId && addrtype == 'send') || (recFirstId && addrtype == 'rec')) && detail.address">{{ (detail.xzqName || "").replace(/,/g, ' ') || '请选择省市区' }}</span>
              <span v-else class="input" :class="{placeholder:!detail.xzqName}" @click="showPicker" style="width:70%">{{ (detail.xzqName || "").replace(/,/g, ' ') || '请选择省市区' }}</span>
            </p>
            <p class="add-info">
              <span class="add-info-label">地 址</span>
              <textarea v-model.trim="detail.address" placeholder="请填写详细地址" maxlength="80" style="flex: 1;" v-if="((sendFirstId && addrtype == 'send') || (recFirstId && addrtype == 'rec')) && detail.address" disabled></textarea>
              <textarea v-else v-model.trim="detail.address" placeholder="请填写详细地址" maxlength="80" style="flex: 1;"></textarea>
            </p>
            <div v-if="isShowBlock" class="address-block"></div>
          </div>
        </section>
        <div class="address-done" @click="saveContact">
          完成
        </div>
      </div>
    </Mpopup>
  </div>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
import Region from "lib/region"
import util from "lib/util"
import  axios  from 'lib/axios'
const { $http } = axios
export default {
  name: 'addressInvite',
  components: {
    Mpopup
  },
  props: {
    value: Boolean,
    detail: {
      type: Object,
      default: ()=> {
        return {
          xzqName: '',
          name: '',
          phone: '',
          address: ''
        }
      }
    },
    sendFirstId:{
      type:String,
      default:() => ''
    },
    recFirstId:{
      type:String,
      default:() => ''
    },
    addrtype: {
      type: String,
      default: 'send'
    }
  },
  data() {
    return {
      addressModel: false,
      showAuto: false,
      autos: [],
      mobileReg: /^1\d{10}$/,
      position: {
        lat: '',
        lng: ''
      },
      haha: '??',
      isShowBlock: true
    }
  },
  watch: {
    value(val) {
      this.addressModel = val
    },
    addressModel(val) {
      this.$emit('input', val)
    },
    'detail.reconize': function() { // 监听剪贴板
      this.rcgTimer && clearTimeout(this.rcgTimer)
      this.rcgTimer = setTimeout(() => {
        this.reconizeContact()
      }, 1500)
    }
  },
  mounted () {
    this.region = new Region({
      others: null,
      level: 2,
      value: (this.detail.xzqName || "").split(","),
      confirm: result => {
        var str = []
        for (let i = 0, len = result.length; i < len; i++) {
          result[i] && (result[i].name) && str.push(result[i].name)
        }
        this.$set(this.detail, 'xzqName', str.join(","))
      },
    })
  },
  methods: {
    focusLoc() {
      this.isShowBlock = false
    },
    blurLoc() {
      this.isShowBlock = true
    },
    turnLoc() {
      // const url = 'https://restapi.amap.com/v3/geocode/regeo'
      const url = '/third/api/gaodeform'
      const params = {
        data: {
          // key: '77d5e24ecae26447c607d3a152b3ddf5',
          location: `${this.position.lng},${this.position.lat}`,
          radius: 300,
          extensions: 'all',
          batch: false,
          roadlevel: 0,
          path: 'v3/geocode/regeo',
          pd: 'SELF',
          client: 'M',
          ref: '/express/sntinvite/address',
          jscode: '6be829187e17a2d6466b65225ea4c131',
          s: 'rsv3'
        },
        emptyUser: true,
        method: 'get'
      }
      this.$http.post(url, params).then((res) => {
        console.log(res.data)
        if (res?.data?.infocode === '10000') {
          if (res.data.regeocode.pois.length) {
            const poi = res.data.regeocode.pois[0]
            const xzq = res.data.regeocode.addressComponent
            let city = xzq.city || ''
            const province = xzq.province.replace('省', '')
            if (['北京市', '天津市', '上海市', '重庆市'].indexOf(city) > -1) {
              city = city.replace('市', '')
            }
            this.detail.xzqName = `${province},${city},${xzq.district}`
            this.detail.address = `${poi.address || ''}${poi.name || ''}`
          }
        }
      }).catch(() => {
        this.$toast('定位失败')
      })
    },
    getLocation() {
      util.locate({
        success: (position, data) => {
          this.position.lat = position.lat
          this.position.lng = position.lng
          if(position.lat) {
            this.turnLoc()
          }
        },
        fail: ()=> {
          this.$toast('定位失败')
        }
      }, this)
    },
    closeMan() {
      this.$emit('closeMan')
    },
    reconizeContact: function(text) { // 智能识别
      if (text || this.detail.reconize) {
        this.$http.post("/order/open/sent.do?method=infosplit", {
          data: {
            content: text || this.detail.reconize
          }
        }).then(result => {
          var data = result.data[0], detail = this.detail
          if (!text) {
            data.name && (detail.name = data.name)
            if (data.mobile?.length && this.mobileReg.test(data.mobile[0])) {
              detail.phone = data.mobile[0]
            }
            data.addr && (detail.address = data.addr)
          }
          data.xzq && data.xzq.fullName && data.xzq.level > 1 && (detail.xzqName = data.xzq.fullName)
          this.$forceUpdate()
        })
      }
    },
    showPicker() {
      // if(((this.sendFirstId && this.addrType == 'send') || (this.recFirstId && this.addrType == 'rec')) && this.detail.xzqName){
      //   return
      // }
      this.region.show((this.detail.xzqName || "").split(","))
    },
    saveContact() {
      if(!this.detail.name || this.detail.name.trim().length < 2) return this.$toast('请输入2-20字的姓名')
      if (!this.mobileReg.test(this.detail.phone)) return this.$toast('请输入正确的11位数手机号')
      if(!this.detail.xzqName) return this.$toast('请补充选择省市区')
      if(!this.detail.address) return this.$toast('请输入3-80字的详细地址')
      // this.$emit('update:detail', this.detail)
      // this.addressModel = false
      this.$emit('confirmMan',this.detail)
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  &-block {
    width: 100%;
    height: 10rem;
  }
  .add-info {
    display: flex;
  }
  &-title {
    font-size: 1rem;
    text-align: center;
    padding: 1.125rem 0 0.8375rem;
    border-bottom: 1px solid #E0E3E6;
  }
  &-done {
    display: block;
    margin: 10px;
    background: #0082FA;
    border-radius: 3px;
    text-align: center;
    line-height: 48px;
    font-size: 1.125rem;
    color: #fff;
  }
}
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.add-box {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.add-box .slide-wrap {
  min-height: 19.8rem;
  -webkit-transition: all .4s;
  transition: all .4s;
  overflow: hidden;
  flex:0;
}
.add-box .slide-wrap.hide {
  min-height: 0;
}
.add-box h4 {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: normal;
  color: #ff7800;
  background-color: #e9e9e9;
}
.add-box p {
  display: -webkit-box;
  margin: 0 0.8rem;
  font-size: 0.875rem;
  background-color: #fff;
  border-bottom: 1px solid rgba($color: #979797, $alpha: 0.14);
}
.add-info-label {
  display: inline-block;
  width:12%;
  line-height: 2.625rem;
  color: #405466;
  padding-right: 1rem;
  font-weight: bold;
}
.cont{
  display: inline-block;
  width: 85%;
}
.add-box p input,.add-box p .input {
  display: block;
  -webkit-box-flex: 1;
  padding: 0;
  height: 2.625rem;
  line-height: 2.625rem;
  color: #333;
  border: 1px solid #fff;
}
.add-box p .input.placeholder {
  color: #aaa;
}
.add-box p textarea {
  display: block;
  -webkit-box-flex: 1;
  margin: 0.5rem 0;
  height: 3rem;
  line-height: 1.5rem;
  color: #5a5a5a;
  border-color: #dedede;
  resize: none;
}
.add-box p textarea.text-l {
  height: 6.25rem;
  border-radius: 0.3125rem;
  padding: 0.45rem;
}
.add-box .link {
  position: relative;
}
.link {
  &-img {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    width: 1.2rem;
    height: 1.3125rem;
  }
}
.add-box .del {
  display: block;
  margin-top: 10px;
  padding-left: 1rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  font-size: 0.875rem;
  color: #ff0000;
  background-color: #fff;
}
.add-box .auto-list {
  flex: 1;
  padding: 0 1em;
  margin-top: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #FFF;
}
.auto-list  li {
  padding: .5em 0;
  border-bottom: 1px solid #dedede;
}
.auto-list small {
  color: #888;
}
.copy {

}
</style>