<template>
  <div class="com">
    <Mpopup v-model="companyModel" :maskClickable="false" @close="closeCom">
      <div class="wrap">
        <h1 class="com-title">选择快递公司</h1>
        <div
          v-for="(item, index) in comList"
          :key="item.name"
          class="comlist"
          @click="selectCom(item)">
          <div class="comlist-main" :class="{'selected': com.capacityId === item.capacityId}">
            <img class="comlist-main__img" :src="`https://cdn.kuaidi100.com/images/all/56/${item.kuaidicom}.png`">
            <div class="comlist-main__info">
              <div class="comlist-main__info--name">
                {{showComName(item)}}
              </div>
              <div v-if="item.serviceType && item.serviceType.length" class="comlist-main__info--label">
                <label
                  v-for="(item_type, index_type) in item.serviceType" 
                  :key="index_type" class="label" 
                  :class="{'service': item.serviceIndex === index_type && com.capacityId === item.capacityId}"
                  @click.stop="selectComByService(item, index_type)">
                  {{item_type}}
                  </label>
              </div>
            </div>
          </div>
        </div>
        <div class="com-done" @click="confirmCom">
          确定
        </div>
      </div>
    </Mpopup>
  </div>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
import util from "lib/util"
import  axios  from 'lib/axios'
const { $http } = axios
export default {
  name: 'companyInvite',
  components: {
    Mpopup
  },
  props: {
    value: Boolean,
    comList: {
      type: Array,
      default: ()=> {
        return []
      }
    },
    comData: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  data() {
    return {
      companyModel: false,
      com: {}
    }
  },
  watch: {
    comData(val) {
      this.com = val
    },
    value(val) {
      this.companyModel = val
    },
    companyModel(val) {
      this.$emit('input', val)
    },
    comList(val) {
      console.log('change')
    }
  },
  mounted () {
    
  },
  methods: {
    showComName({kuaidicom:code,capacityId}) {
      if (code) {
        const KUAIDICOMCNTRANS = {
          88: '圆通-SW',
          89: '圆通-DD',
          90: '圆通-TS'
        };
        if(KUAIDICOMCNTRANS[capacityId]){
          return KUAIDICOMCNTRANS[capacityId]
        }
        return jsoncom.company.filter(item => { // 收件人id
          return item.number === code
        })[0].shortName
      }else {
        return ''
    }
    },
    selectComByService(item, index_type) { // 选择公司
      this.com = item
      this.$set(this.com, 'serviceIndex', index_type)
    },
    closeCom() {
      this.com = this.comData
    },
    selectCom(item) { // 选择公司
      this.com = item
      this.$set(this.com, 'serviceIndex', 0)
    },
    confirmCom() {
      this.$emit('update:comData', this.com)
      this.companyModel = false
      console.log(this.com)
    }
  }
}
</script>

<style lang="scss" scoped>
.com {
  &-done {
    display: block;
    margin: 10px;
    background: #0082FA;
    border-radius: 3px;
    text-align: center;
    line-height: 48px;
    font-size: 1.125rem;
    color: #fff;
  }
  .wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-title {
    font-size: 1rem;
    text-align: center;
    padding: 1.125rem 0 0.8375rem;
  }
  .comlist {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.9375rem;
    &-main {
      display: flex;
      align-items: center;
      height: 4.0625rem;
      border: 1px solid #D1D6DA;
      border-radius: 4px;
      margin-bottom: 0.625rem;
      &.selected {
        border: 1px solid #0A84FF;
      }
      &__img {
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 50%;
        margin: 0 0.5625rem;
      }
      &__info {
        &--name {
          font-size: 0.875rem;
          color: #333;
          font-weight: bold;
          width: 100%;
        }
        &--label {
          .label {
            color: #878787;
            font-size: 0.6875rem;
            border: 1px solid;
            border-radius: 4px;
            padding: 1px 0.5rem;
            text-align: center;
            line-height: 1.125rem;
            margin-right: 0.625rem;
            /* &:first-child {
              color: #0082FA;
              background: rgba(0, 130, 250, 0.03);
            } */
            &.service {
              color: #0082FA;
              background: rgba(0, 130, 250, 0.03);
            }
          }
        }
      }
    }
  }
}
</style>