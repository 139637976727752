<template>
  <Modal v-model="isShowLogin" hideFooter showClose modalStyle="width: 20.3125rem;" boxStyle="padding: 1.6875rem 0.9375rem;">
    <div class="valid">
      <div v-if="loginType === 'submit'" class="valid-title">为保证数据安全，请使用手机{{phone | hidePhone}}获取短信验证码验证</div>
      <div v-if="loginType !== 'submit'" class="valid-phonetitle">手机号快捷登录</div>
      <div v-if="loginType !== 'submit'" class="valid-sms">
        <input class="valid-sms__input" placeholder="请输入手机号" v-model="form.input" />
        <span class="valid-sms__resend">手机号码</span>
      </div>
      <div v-if="loginType === 'submit' || isShowImg" class="valid-sms">
        <input class="valid-sms__input" placeholder="请输入图片验证码" v-model="form.valid" />
        <img class="valid-sms__img" :src="`https://m.kuaidi100.com/pcweb/verifycode/generateverifycode?method=getVerification&random=${random}&name=${loginType === 'submit' ? phone : form.input}`" alt="验证码" @click="random = Math.random()">
      </div>
      <div class="valid-sms">
        <input class="valid-sms__input" placeholder="请输入短信验证码" v-model="form.code" />
        <span v-if="code.second === 60" class="valid-sms__get" @click="sendCode">获取短信验证码</span>
        <span v-else class="valid-sms__resend">{{code.second}}s重新发送</span>
      </div>
      <button class="valid-btn" @click="submit">确定</button>
    </div>
  </Modal>
</template>

<script>
import Modal from "components/Modal/Modal"
import util from "lib/util"
import  axios  from 'lib/axios'
const { $http } = axios
export default {
  name: 'loginInvite',
  components: {
    Modal
  },
  props: {
    value: Boolean,
    loginType: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
  },
  filters: {
    hidePhone: (val)=> {
      if(!val) return ''
      return val.replace(/(\d{3}).{4}(\d{4})/, '$1****$2')
    }
  },
  computed: {
    isShowImg() {
      if(this.loginType === 'input' && this.mobileReg.test(this.form.input)) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      isShowLogin: false,
      smsCode: '',
      form: { input: '', code: '', valid: '' },
      random: Math.random(),
      mobileReg: /^1\d{10}$/,
      calcTimer: null,
      code: {second: 60},
    }
  },
  created() {
    
  },
  watch: {
    value(val) {
      this.isShowLogin = val
    },
    isShowLogin(val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    
  },
  methods: {
    submit: function(){
      let loading = this.$loading('正在登录')
      var data = {
        phone: this.loginType === 'input' ? this.form.input : this.phone,
        code: this.form.code,
        source: "mexpress"
      }
      const url = "/pcweb/login/webmobilelogin?method=webclogin"
      this.$http.post(url, {
        data: data
      }).then(rst => {
        var store = this.$store.state.globalData
        util.setcookie("TOKEN", rst.token)
        this.$store.commit("setGlobal", ({token: rst.token}))
        if(store.coname && store.appid && store.openid || store.isWechat && !store.isMiniProgram) {//第三方接入用户和快递100公众号登录，则记录会话的本地存储
          util.setSession("TOKEN", rst.token)
        } else {//第三方公众号接入且没有接入用户信息的，则记录长期的TOKEN，在个人中心提供退出登录功能供用户自行退出
          util.setcookie('TOKEN', rst.token)
        }
        this.$emit('loginSuccess', this.loginType === 'submit' ? 'submit' : '')
        this.isShowLogin = false
      }).catch(rst => {
        this.$toast(rst.message)
      }).finally(()=> {
        loading.hide()
      })
    },
    sendCode() {
      if (this.code.second !== 60) return;
      if(this.loginType === 'inout' && !this.mobileReg.test(this.form.input)) {
        return this.$toast('请输入正确的手机号码', 1000)
      }
      this.$http.post('/pcweb/sms/loginsendsms', {
        data: {
          name: this.loginType === 'input' ? this.form.input : this.phone,
          validcode: this.form.valid
        },
        handleFail: false
      }).then(() => {
        this.$toast("验证码已经发送到您的手机")
        this.calcTime()
      }).catch(r => {
        if(r.status == '10010') {
          this.$toast("请输入图片验证码")
        } else if(r.status == '10011'){
          this.$toast("图片验证码错误", 1000)
        } else {
          this.$toast(r.message)
        }
      })
    },
    calcTime: function(){
      this.calcTimer && clearInterval(this.calcTimer);
      this.calcTimer = setInterval(() => {
        if(!this.code.second--) {
          clearInterval(this.calcTimer)
          this.calcTimer = null
          this.code.second = 60
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.valid {
  &-phonetitle {
    color: #333;
    font-weight: bold;
    text-align: center;
    padding-bottom: 1rem;
  }
  &-title {
    font-size: 0.875rem;
    color: #001A32;
    padding-bottom: 0.625rem;
  }
  &-sms {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
    justify-content: space-between;
    &__input {
      width: 10.5rem;
      height: 2.375rem;
      border-radius: 0.625rem;
      background: #f5f5f5;
      font-size: 0.8125rem;
      margin-right: 0.875rem;
    }
    &__img {
      width: 6rem;
      height: 2.375rem;
    }
    &__get {
      font-size: 0.8125rem;
      color: #0A84FF;
    }
    &__resend {
      font-size: 0.875rem;
      color: #B2BAC1;
    }
  }
  &-btn {
    width: 18rem;
    height: 2.6875rem;
    border-radius: 0.625rem;
    background: #0082FA;
    font-size: 1rem;
    color: #fff;
    text-align: center;
    line-height: 2.6875rem;
    margin-top: 0.6rem;
  }
}
</style>