<template>
  <Mpopup v-model="cargoModal" :maskClickable="false" @close="closeCargo">
    <div class="cargomain">
      <div class="cargomain-top">
        <div class="cargo">         
          <div class="cargo-title" v-show="!cargoName"><span>物品名称（必填）</span></div>
          <div class="cargo-title" v-show="cargoName"><span>物品名称<span class="gary">（不可修改）</span></span></div>
          <div class="cargo-detail">
            <div
              v-for="(item, index) in cargoList"
              :key="index"
              class="cargo-detail__name"
              :class="{'selected' : cargoData.cargo === item.name}"
              @click="selectCargo(item)"
              v-show="!cargoName"
            >{{ item.name }}</div>
          </div>
          <div class="cargo-detail__name cargo-detail__newname" v-show="cargoName">{{ cargoName }}</div>
          <div v-if="cargoData.cargo === '其他'" class="cargo-other">
            <input v-model.trim="cargoData.cargoInput" class="cargo-other__input" placeholder="请输入物品名称" maxlength="12" style="width:100% " >
          </div>
          <div class="cargo-title" style="margin-top:10px"><span>物品备注</span></div>
          <div class="cargo-backup">
            <textarea v-model="cargoData.cargoComment" class="cargo-backup__text" placeholder="请输入备注信息" />
          </div>
        </div>
      </div>
      <div class="cargomain-done" @click="confirmCargo">
        完成
      </div>
    </div>
  </Mpopup>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
import util from "lib/util"
import axios from 'lib/axios'
export default {
  name: 'addressInvite',
  components: {
    Mpopup
  },
  props: {
    value: Boolean,
    cargoData: {
      type: Object,
      default: ()=> {
        return {
          cargo: '',
          cargoComment: '',
          cargoInput: ''
        }
      }
    },
    cargoName: {
      type: String,
      default:''
    }
  },
  data() {
    return {
      cargoModal: false,
      cargoList: [{ name: '日用品', data: ['含液体', '含电池'] },
        { name: '服饰' },
        { name: '文件', data: ['含法律公文'] },
        { name: '食品', data: ['含液体'] },
        { name: '证件' },
        { name: '数码家电', data: ['含电池'] },
        { name: '其他', data: ['含液体', '含电池'] }
      ],
      cargoIndex: -1
    }
  },
  watch: {
    value(val) {
      this.cargoModal = val
    },
    cargoModal(val) {
      this.$emit('input', val)
    },
  },
  mounted () {
    
  },
  methods: {
    closeCargo() {
      this.$emit('cancelCargo')
    },
    selectCargo(item) {
      this.cargoData.cargo = item.name
    },
    confirmCargo() {
      if(this.cargoData.cargo === '其他' && !this.cargoData.cargoInput) {
        return this.$toast('请填写物品信息')
      }
      if (this.cargoData.cargo !== '其他') {
        this.cargoData.cargoInput = ''
      }
      this.cargoModal = false
      this.$emit('update:cargoData', this.cargoData)
    }
  }
}
</script>

<style lang="scss" scoped>
.cargomain {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.9375rem 0;
  .cargo-other__input::placeholder {-webkit-text-fill-color: #7F8C98;}
  &-done {
    display: block;
    margin: 10px 0;
    background: #0082FA;
    border-radius: 3px;
    text-align: center;
    line-height: 48px;
    font-size: 1.125rem;
    color: #fff;
  }
  &-top {
    flex: 1;
    width: 100%;
    overflow: scroll;
    padding-bottom: 100rpx;
  }  
  .cargo {
    padding-bottom: 10rem;
    .gary{color: #818080;}
    &-backup {
      margin-top: 0.9rem;
      display: flex;
      &__text {
        flex: 1;
        -webkit-box-flex: 1;
        height: 4.6825rem;
        border-radius: 0.3125rem;
        padding: 0.45rem;
        line-height: 1.5rem;
        color: #5a5a5a;
        resize: none;
        background: #F0F2F3;
      }
    }
    &-other {
      border-bottom: 1px solid #7F8C98;
      margin: 0.5rem 0 0.5rem;
      &__input {
        font-size: 0.875rem;
        padding-bottom: 0.5rem;        
      }
    }
    &-title {
      font-size: 1rem;
      color: #333;
      font-weight: bold;
    }
    &-detail {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      &__name {
        background: #E0E3E6;
        width: 4.625rem;
        line-height: 1.5625rem;
        font-size: 0.8125rem;
        color: #7F8C98;
        text-align: center;
        margin-right: 0.625rem;
        margin-top: 0.625rem;
        border-radius: 0.2rem;       
        &.selected {
          background: #0A84FF;
          color: #fff;
        }
      }
      &__newname{
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
        background-color: #0A84FF;
      }
    }
  }
}
</style>